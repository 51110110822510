import VCard from "vcard-creator";
import { saveAs } from "file-saver";

const createVcj = (person) => {
  const vCard = new VCard();

  vCard.addName(person.lastName, person.firstName);

  if (person.orgName) {
    vCard.addCompany(person.orgName);
  }

  if (person.email) {
    vCard.addEmail(person.email, "PREF;WORK");
  }

  if (person.pec) {
    vCard.addEmail(person.pec, "PREF;WORK");
  }

  if (person.mobile) {
    vCard.addPhoneNumber(person.mobile, "PREF;WORK");
  }

  if (person.phone) {
    vCard.addPhoneNumber(person.phone, "PREF;WORK");
  }

  if (person.website) {
    vCard.addURL(person.website);
  }

  (function () {
    const blob = new Blob([vCard], { type: "text/vcard;charset=utf-8" });
    saveAs(blob, `${person.firstname}${person.lastname}.vcf`);
  })();
};
export default createVcj;
