import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import createVcj from "./createVcf";
import afLogo from "../media/annunci-funebri.svg";
import blsrlLogo from "../media/blsrl-logo.png";
import giesseLogo from "../media/giesse.png";
import ReactGA from "react-ga4";
import Loader from "./loader";
import QrModalCard from "./QrModalCard";
import InvoicingModalCard from "./InvoicingModalCard";

function CardPage() {
  const [person, setPerson] = useState();
  const [loading, setLoading] = useState(true);
  const [isInvoicingModalOpen, setIsInvoicingModalOpen] = useState(false);
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);
  const { orgName, personFullName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async (orgName, personFullName) => {
      try {
        const data = await fetch(
          `https://${process.env.REACT_APP_CARDS_API_URL}/slug/?slug=${orgName}/${personFullName}`
        );

        const json = await data.json();
        debugger;
        setPerson(json);
        setLoading(false);
      } catch (error) {
        console.log(error);
        navigate("/");
      }
    };

    fetchData(orgName, personFullName).catch(console.error);
  }, [orgName, personFullName, navigate]);

  const displayEmail = () => {
    const textClassName =
      person.data.email?.length >= 35 ? "text-extraSm" : "text-sm";

    return <p className={`${textClassName} truncate`}>{person.data.email}</p>;
  };

  const displayAddress = () => {
    const fullAddress = (person.data.city + " " + person.data.address).replace(
      /\s/g,
      "+"
    );

    return (
      <a
        href={`http://maps.apple.com/?q=${fullAddress}`}
        target="_blank"
        rel="noopener noreferrer"
        className="h-full flex items-center leading-5"
      >
        <Icon
          icon="carbon:location-company-filled"
          className="text-4xl text-ofcBlue ml-1 mr-3"
        />
        <div>
          <p className="text-ofcBlue font-medium">Indirizzo</p>
          <p>{person.data.address}</p>
          <p>{person.data.city}</p>
        </div>
      </a>
    );
  };

  const shouldShowInvoicing =
    person?.data.vat || person?.data.electronicInvoicingId || person?.data.pec;

  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <div className="h-90v flex justify-center items-center font-Poppins">
          <div className="w-96 h-40 mx-4 rounded-lg  shadow-2xl bg-white relative ">
            <div className="h-28 bg-ofcBlue rounded-t-lg flex items-center justify-center mb-5 relative">
              <div id="perfil-wrap" className="h-30">
                {shouldShowInvoicing && (
                  <button
                    onClick={() => {
                      setIsInvoicingModalOpen(true);
                      ReactGA.event({
                        category: "CardPage",
                        action: "Click",
                        label: "Modal Fatturazione",
                      });
                    }}
                    className="absolute left-2 top-2"
                  >
                    <Icon
                      className="text-xl text-white"
                      icon="ant-design:euro-outlined"
                    />
                  </button>
                )}

                <button
                  onClick={() => {
                    setIsQrModalOpen(true);
                    ReactGA.event({
                      category: "CardPage",
                      action: "Click",
                      label: "Modal QR",
                    });
                  }}
                  className="absolute top-2 right-2"
                >
                  <Icon className="text-xl text-white" icon="ion:qr-code" />
                </button>

                <h1 className="text-2xl text-white font-bold text-center font-Poppins">
                  {person.data.firstName + " " + person.data.lastName}
                </h1>
                <h2 className="text-xl text-white text-center">
                  {person.data.orgName}
                </h2>
              </div>
            </div>

            {/* ***Company*** */}
            {(person.data.city || person.data.address) && (
              <div className="w-80 h-20 bg-infoBg mx-auto  rounded-md mb-5">
                {displayAddress()}
              </div>
            )}

            {/* ***Phone Details** */}
            {(person.data.mobile || person.data.phone) && (
              <div className="w-80 h-16 bg-infoBg mx-auto flex items-center rounded-md mb-5">
                <Icon
                  icon="ant-design:phone-filled"
                  className="text-4xl text-ofcBlue ml-1 mr-3"
                />
                <div className="w-full flex items-center justify-between">
                  {person.data.phone && (
                    <a
                      href={`tel:${person.data.phone}`}
                      onClick={() => {
                        ReactGA.event({
                          category: "CardPage",
                          action: "Click",
                          label: "Telefono",
                        });
                      }}
                    >
                      <div className="telefono">
                        <p className="text-ofcBlue font-medium">Telefono</p>

                        {person.data.phone}
                      </div>
                    </a>
                  )}
                  {person.data.mobile && (
                    <a
                      href={`tel:${person.data.mobile}`}
                      onClick={() => {
                        ReactGA.event({
                          category: "CardPage",
                          action: "Click",
                          label: "Cellulare",
                        });
                      }}
                    >
                      <div className="cellulare mr-6">
                        <p className="text-ofcBlue font-medium">Cellulare</p>
                        {person.data.mobile}
                      </div>
                    </a>
                  )}
                </div>
              </div>
            )}

            {/* ***Email Address*** */}
            {person.data.email && (
              <a href={`mailto:${person.data.email}`}>
                <div className="w-80 h-16 bg-infoBg mx-auto flex items-center rounded-md mb-5">
                  <Icon
                    icon="ic:baseline-email"
                    className="text-4xl text-ofcBlue ml-1 mr-3"
                  />
                  <div className="w-10/12">
                    <p className="text-ofcBlue font-medium">Email</p>
                    {displayEmail()}
                  </div>
                </div>
              </a>
            )}

            {/* ***Website*** */}
            {person.data.website && (
              <a
                href={
                  person.data.website.startsWith("https")
                    ? person.data.website
                    : `https://${person.data.website}`
                }
                target="_blank"
                rel="noreferrer"
                className="text-sm truncate"
              >
                <div className="w-80 h-16 bg-infoBg mx-auto flex items-center rounded-md mb-5">
                  <Icon
                    icon="mdi:web"
                    className="text-4xl text-ofcBlue ml-1 mr-3"
                  />

                  <div className="w-10/12 truncate">
                    <p className="text-ofcBlue font-medium">Sito Web</p>

                    {
                      person.data.website
                        ?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
                        .split("/")[0]
                    }
                  </div>
                </div>
              </a>
            )}

            <div className="w-80 h-16 mx-auto flex items-center justify-between">
              <a
                href={
                  person.data.mobile
                    ? `tel:${person.data.mobile}`
                    : `tel:${person.data.phone}`
                }
                onClick={() => {
                  ReactGA.event({
                    category: "CardPage",
                    action: "Click",
                    label: "Chiama",
                  });
                }}
                className="w-36 h-12 bg-ofcDarkBlue text-white text-center leading-[3rem] font-bold  rounded-lg "
              >
                Chiama
              </a>
              <button
                onClick={() => {
                  ReactGA.event({
                    category: "CardPage",
                    action: "Click",
                    label: "Salva in Rubrica",
                  });
                  return createVcj(person.data);
                }}
                className="w-36 h-12 bg-ofcGreen text-white font-bold px-4 rounded-lg leading-4"
              >
                Salva in Rubrica
              </button>
            </div>

            <div className="h-14 w-full bg-ofcBlue flex items-center justify-around absolute bottom-0 rounded-b-lg">
              <a
                href="https://onoranzefunebricloud.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  width="42"
                  height="42"
                  src="https://cdn.onoranzefunebricloud.com/logos/2022/ofc_logo_rounded_white_100.png"
                  alt="Logo Onoranzefunebricloud"
                />
              </a>
              <a
                href="https://www.annuncifunebri.it/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={afLogo} alt="Logo AnnunciFunebri.it" />
              </a>
              <a href="https://www.blsrl.net/" target="_blank" rel="noreferrer">
                <img
                  src={blsrlLogo}
                  width="42"
                  height="42"
                  alt="Logo BL S.R.L "
                />
              </a>
              <a
                href="https://www.giesse.info/it/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  width="40"
                  height="40"
                  src={giesseLogo}
                  alt="Logo Giesse S.R.L "
                />
              </a>
            </div>
          </div>
        </div>
      )}
      <QrModalCard
        isQrModalOpen={isQrModalOpen}
        setIsQrModalOpen={setIsQrModalOpen}
      />
      {shouldShowInvoicing && (
        <InvoicingModalCard
          isInvoicingModalOpen={isInvoicingModalOpen}
          setIsInvoicingModalOpen={setIsInvoicingModalOpen}
          person={person.data}
        />
      )}
    </div>
  );
}

export default CardPage;
